import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={"Linear Search | A Helpful Line-by-Line Code Tutorial"}
				subHeader={""}
				description={""}
				keywords={[]}
				embeddedVideo={"_w8xb-XhcAE"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/d13dd1ffed169a4d822acf26d3e8d7cd/raw/297266f4bdbe404704e67eb88575772377128f05/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
